import Grouping from '@/data/shared/Grouping';

export default class GroupingList<TKey, TElement> extends Array<Grouping<TKey, TElement>> {
  public upsert(key: TKey, element: TElement) {
    const item = this.find((grouping) => grouping.key === key);
    if (!item) {
      this.add(key, element);
      return;
    }
        
    item.push(element);
  }
    
  public add(key: TKey, element: TElement): number {
    return this.push(new Grouping(key, [element]));
  }
}
