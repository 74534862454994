import GroupingList from '@/data/shared/GroupingList';

export const exclude = <T>(firstArray: T[], secondArray: T[], keySelector: (element: T) => string) => {
    if (!firstArray.length || !secondArray.length) {
        return firstArray;
    }

    return firstArray.filter(x => secondArray.every(y => keySelector(x) !== keySelector(y)));
}

export const remove = <T>(array: T[], predicate: (element: T) => boolean) => {
    const index = array.findIndex(predicate);
    if (index < 0) {
        return;
    }

    array.splice(index, 1);
};

export const sortBy = <T>(source: T[], getKey: (value: T) => string) => {
    source.sort((a, b) => getKey(a).toLowerCase().localeCompare(getKey(b).toLowerCase()));
};

export function groupBy<TKey, TElement>(
    array: Array<TElement>,
    criteria: (item: TElement) => TKey,
): GroupingList<TKey, TElement> {
    return array.reduce((collection: GroupingList<TKey, TElement>, item) => {
        const key = criteria(item);
        collection.upsert(key, item);
        return collection;
    }, new GroupingList<TKey, TElement>());
}
