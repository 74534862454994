import axios from "axios";
import me from "./me";

import { Organization } from "@/data/organizations/organization";
import OrganizationCounters from "@/data/organizations/organizationCounters";
import { apiUrl } from "@/Config";
import { Guid } from "guid-typescript";
import { DefaultSelectionFilter } from "@/data/shared/SelectionFilter";
import SelectionResult from "@/helpers/SelectionResult";
import OrganizationStatus from "@/data/organizations/organizationStatus";
import OrganizationTenant from "@/data/organizations/organizationTenant";
import OrganizationForm from "@/forms/OrganizationForm";
import { OrganizationDetails } from "@/data/organizations/organizationDetails";

export default new class OrganizationService {
  public async loadOrganizationCountersAsync(filter: DefaultSelectionFilter): Promise<SelectionResult<OrganizationCounters>> {
    return await axios
      .get<SelectionResult<OrganizationCounters>>(`${apiUrl}/api/organizations?${filter.toQuery()}`)
      .then(item => item.data);
  }

  public getOrganizationAsync(organizationId?: Guid): Promise<Organization> {
    return axios
      .get<Organization>(`${apiUrl}/api/organizations/${me(organizationId)}`)
      .then(x => x.data);
  }

  public async createOrganizationAsync(tenant: string) {
    return await axios
      .post(`${apiUrl}/api/organizations`, {
        tenant
      });
  }

  public async updateOrganizationAsync(organizationForm: OrganizationForm) {
    return axios.put(`${apiUrl}/api/organizations`, organizationForm);
  }

  public async updateMeOrganizationAsync(organizationForm: OrganizationForm) {
    return axios.put(`${apiUrl}/api/organizations/me`, organizationForm);
  }

  public async restoreOrganizationAsync(organizationId: Guid) {
    return await axios
      .patch(`${apiUrl}/api/organizations/${organizationId}`);
  }

  public async deleteOrganizationAsync(organizationId: Guid) {
    return await axios
      .delete(`${apiUrl}/api/organizations/${organizationId}`);
  }

  public async getOrganizationStatusAsync(tenant: string) {
    return await axios
      .get<OrganizationStatus>(`${apiUrl}/api/organizations/exists?tenant=${tenant}`)
      .then(item => item.data);
  }

  public async getMeOrganizationStatusAsync(tenant: string) {
    return await axios
      .get<OrganizationStatus>(`${apiUrl}/api/organizations/me/exists?tenant=${tenant}`)
      .then(item => item.data);
  }

  public async getOrganizationTenant(organizationId?: Guid) {
    return await axios
      .get<OrganizationTenant>(`${apiUrl}/api/organizations/${me(organizationId)}/tenant`)
      .then(item => item.data);
  }

  public async getDetailsAsync(organizationId?: Guid) {
    return await axios
    .get<OrganizationDetails>(`${apiUrl}/api/organizations/${me(organizationId)}/details`)
    .then(x => x.data);
  }
}();
