import { StateStore } from "oidc-client";
import SecureLS from "secure-ls";

export default class SecureStorage implements StateStore {
    private readonly storage: SecureLS;

    constructor() {
        this.storage = new SecureLS({ isCompression: false });
    }

    public set(key: string, value: any): Promise<void> {
        return new Promise<void>(resolve => {
            resolve(this.storage.set(key, value));
        });
    }

    public get(key: string): Promise<any> {
        return new Promise<any>(resolve => {
            resolve(this.storage.get(key));
        });
    }

    public remove(key: string): Promise<any> {
        return new Promise<void>(resolve => {
            resolve(this.storage.remove(key));
        });
    }

    public getAllKeys(): Promise<string[]> {
        return new Promise<string[]>(resolve => {
            resolve(this.storage.getAllKeys());
        });
    }
}
