export const parseJwt = (token: string) => {
    try {
        const base64 = token
            .split(".")[1]
            .replace("-", "+")
            .replace("_", "/");
        return JSON.parse(window.atob(base64));
    } catch (error) {
        return {};
    }
};

export const tokenExpiration = (token: string | undefined | null): number | undefined | null => {
    if (!token) {
        return undefined;
    }

    const jwtToken = parseJwt(token);
    return jwtToken.exp
        ? jwtToken.exp * 1000
        : null;
};

export const tokenIsExpired = (token: string | undefined | null) => {
    const tokenExpirationTime = tokenExpiration(token);
    return tokenExpirationTime &&
           tokenExpirationTime < new Date().getTime();
};

export const getTokenIfNotExpired = (token: string | undefined | null) => {
    return tokenIsExpired(token) ? null : token;
};

export const getCallbackPath = (callbackUri?: string, url: string = "/"): string | null => {
    return callbackUri
        ? callbackUri.substr(callbackUri.indexOf(url) + url.length).replace(/\/$/, "")
        : null;
};
