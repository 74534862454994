
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "dropdown" })
export default class Dropdown extends Vue {
  @Prop({ default: "fa-chevron-down" }) public readonly icon?: string;
  private active = false;

  public toggle() {
    this.active = !this.active;
  }
}
